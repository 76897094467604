var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('aboutUs.title')) + " ")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-row', {
    attrs: {
      "align": "center",
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiCardAccountDetailsOutline))])], 1), _c('v-col', {
    staticClass: "title",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('aboutUs.contact.title')))])], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiLabelOutline))])], 1), _c('v-col', {
    staticClass: "title",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.$t('aboutUs.other.title')) + " ")])], 1), _c('p', [_vm._v(" Copyright"), _c('br'), _c('i', [_vm._v("Frachtwerk GmbH, 2017-" + _vm._s(_vm.currentYear) + ", All Rights reserved")])]), _c('br')], 1), _c('v-col', [_c('v-row', {
    attrs: {
      "align": "center",
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiMapMarkerOutline))])], 1), _c('v-col', {
    staticClass: "title",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('aboutUs.locations.title')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }