






































import { defineComponent } from '@vue/composition-api'
import { mdiCardAccountDetailsOutline, mdiMapMarkerOutline, mdiLabelOutline } from '@mdi/js'
export default defineComponent({
  name: 'AboutUsView',
  setup: () => {
    const currentYear = new Date().getFullYear()

    return {
      icons: {
        mdiCardAccountDetailsOutline,
        mdiMapMarkerOutline,
        mdiLabelOutline,
      },
      currentYear,
    }
  },
})
